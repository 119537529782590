/* eslint-disable import/prefer-default-export */
export const getCategories = `
{
    categories {
        items {
            uid
            name
            level
            path
            url_path
            url_key
            include_in_menu
            position_in_menu
            custom_url_link
            is_custom_url_link
            children_count
            children {
                uid
                name
                level
                path
                url_path
                url_key
                include_in_menu
                position_in_menu
                custom_url_link
                is_custom_url_link
                children_count
                children {
                    uid
                    name
                    level
                    path
                    url_path
                    url_key
                    include_in_menu
                    position_in_menu
                    custom_url_link
                    is_custom_url_link
                    children_count
                    children {
                        uid
                        name
                        level
                        path
                        url_path
                        url_key
                        include_in_menu
                        position_in_menu
                        custom_url_link
                        is_custom_url_link
                        children_count
                    }
                }
            }
        }
    }
}
`;
export const storeConfig = `
{
    storeConfig {
        allow_guest_checkout
        allow_guests_to_write_product_reviews
        base_currency_code
        base_media_url
        base_static_url
        base_url
        catalog_default_sort_by
        catalog_search_engine
        category_url_suffix
        cms_page
        code
        cookie_restriction
        copyright
        customer_password_minimum_password_length
        customer_password_required_character_classes_number
        date_of_birth
        default_description
        default_display_currency_code
        default_keywords
        default_title
        enable_oms_multiseller
        forgot_password_phone
        gender
        general_country_default
        header_logo_src
        head_shortcut_icon
        locale
        login_phone_password
        logo_alt
        logo_height
        logo_width
        minimum_order_amount
        minimum_order_enable
        oauth_access_token_lifetime_customer
        payments_configuration
        pickup_store
        pin_location_latitude
        pin_location_longitude
        mp_dailydeal_enabled
        mp_dailydeal_custom_timesale
        mp_dailydeal_custom_timesale_period
        amrewards_general_enabled
        amrewards_notification_send_earn
        amrewards_notification_send_expire
        amrewards_highlight_cart
        amrewards_highlight_category
        amrewards_highlight_checkout
        amrewards_highlight_guest
        amrewards_highlight_product
        amrewards_highlight_registration_link
        widget_general_cms_block
        header_animation_enable
        header_animation_cms_block
        midtrans_core_api_environment
        firebase_setting_sociallogin_is_production
        widget_general_store_availability
        pwa {
            add_to_cart_enable
            app_name
            background_color
            button_background_color
            button_background_hover_color
            button_border_color
            button_border_hover_color
            button_disabled_background_color
            button_disabled_text_color
            button_text_color
            button_text_hover_color
            cms_contact_identifiers
            configurable_options_enable
            custom_install_app_enable
            default_font
            default_robot
            drawer_filter_on_desktop_enable
            enabler_footer_mobile
            enabler_sticky_header
            error_color
            facebook_app_id
            facebook_meta_id_app_id
            facebook_meta_id_enable
            font_color
            heading_font
            home_slider_desktop_height
            home_slider_desktop_width
            home_slider_mobile_height
            home_slider_mobile_width
            icon_apple_touch
            image_category_height
            image_category_width
            image_product_height
            image_product_width
            install_message
            label_enable
            label_new_enable
            label_sale_enable
            label_weltpixel_enable
            link_color
            link_font_decoration
            link_font_hover_decoration
            link_hover_color
            magezon_slider_desktop_height
            magezon_slider_desktop_width
            magezon_slider_mobile_height
            magezon_slider_mobile_width
            megamenu_cms_block
            page_size
            paypal_debug
            paypal_enable
            paypal_merchant_id
            popup_detail_image_enable
            primary_color
            product_listing_navigation
            quick_view_enable
            rating_enable
            recaptcha_contact_enable
            recaptcha_enable
            recaptcha_login_enable
            recaptcha_register_enable            
            remove_decimal_price_enable
            secondary_color
            share_icon_email
            share_icon_facebook
            share_icon_line
            share_icon_linkedin
            share_icon_pinterest
            share_icon_telegram
            share_icon_twitter
            success_msg_color
            thumbor_enable
            thumbor_https_http
            thumbor_url
            use_cms_page_enable
            use_cms_page_identifier
            warning_msg_color
            gtm_enable
            gtm_id_local
            gtm_id_dev
            gtm_id_stage
            gtm_id_prod
        }
        pwa_checkout_debug_enable
        secure_base_media_url
        secure_base_static_url
        shipments_configuration
        snap_is_production
        store_name
        timezone
        title_prefix
        title_separator
        title_suffix
        website_id
        weight_unit
        welcome
        weltpixel_newsletter_general_display_mobile
        weltpixel_newsletter_general_display_mode
        weltpixel_newsletter_general_enable
        weltpixel_newsletter_general_enable_trigger_button
        weltpixel_newsletter_general_mobile_version
        weltpixel_newsletter_general_overlay_color
        weltpixel_newsletter_general_overlay_opacity
        weltpixel_newsletter_general_popup_cookie_lifetime
        weltpixel_newsletter_general_terms_conditions_consent
        weltpixel_newsletter_general_terms_conditions_text
        weltpixel_newsletter_general_trigger_button_backgroundcolor
        weltpixel_newsletter_general_trigger_button_color
        weltpixel_newsletter_general_trigger_button_title
        weltpixel_newsletter_general_version
        weltpixel_RecentlyViewedBar_general_enable
        weltpixel_thankyoupage_create_account_enable
        OmsRma{
          enable_oms_rma
          enable_oms_pwa_request_return
          oms_rma_link
        }
        oms_channel_code
        snap_is_production
    }
}  
`;
export const frontendOptions = `
{
    storeConfig {
        pwa {
            primary_color
            secondary_color
            background_color
            error_color
            warning_msg_color
            success_msg_color
            font_color
            default_font
            heading_font
            button_background_color
            button_background_hover_color
            button_disabled_text_color
            button_disabled_background_color
            button_text_color
            button_text_hover_color
            button_border_color
            button_border_hover_color
            link_color
            link_hover_color
            link_font_decoration
            link_font_hover_decoration
        }
    }
}
`;
export const getCmsList = `
{
  storeConfig {
    cms_page  
    category_url_suffix
  }
}
`;
